import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';
import { Api } from '../api/Api';
import { initializeKeycloak } from '../auth/keycloak';

export function loadConfig(http: HttpClient, keycloak: KeycloakService) {
  return () =>
    new Promise((resolve, reject) => {
      return http.get(Api.Config).subscribe((resp: any) => {
        initializeKeycloak(keycloak, resp.keycloakHost)
          .then(() => resolve(true))
          .catch((err) => reject(err));
      });
    });
}
