<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand is-hidden-widescreen-only">
    <a class="navbar-item">
      <img height="50" src="https://cdn.meinkontowechsel.de/logo.fino.png" />
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-start is-align-items-center">
      <div *ngFor="let route of routes">
        <a
          class="navbar-item"
          *ngIf="hasRole(route.role) || hasOrganization(route.organizations)"
          [routerLink]="route.url"
          routerLinkActive="active-link"
        >
          <div class="tags has-addons">
            <span
              class="tag is-dark has-text-weight-bold is-size-6"
              [class.has-no-counter]="route.showNoLocks"
              >{{ route.name + ".title" | translate }}</span
            >
            <span
              class="tag has-background-grey-darker counter is-size-6"
              *ngIf="!route.showNoLocks"
              ><fa-icon [icon]="faLockOpen" class="has-text-white"></fa-icon>
              <span class="has-text-white has-text-weight-bold mr-1"
                >{{ counts[route.name]?.open }}
              </span>
              <fa-icon [icon]="faLock" class="has-text-danger"></fa-icon>
              <span class="has-text-danger has-text-weight-bold">
                {{ counts[route.name]?.locked }}
              </span></span
            >
          </div>
        </a>
      </div>

      <div
        class="navbar-item has-dropdown is-hoverable"
        *ngIf="hasMoreDropwdown"
      >
        <a class="navbar-link" translate="navbar.more"></a>

        <div class="navbar-dropdown">
          <a
            class="navbar-item has-text-grey"
            *ngIf="hasRole(Roles.CLARIFICATION)"
            routerLinkActive="has-text-primary"
            [routerLink]="clarificationRoute"
            translate="navbar.clarification"
          ></a>
        </div>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item has-dropdown is-hoverable country-select">
        <a class="navbar-link"
          ><img src="/assets/country-icons/{{ currentLang }}.svg"
        /></a>
        <div class="navbar-dropdown">
          <a class="navbar-item" (click)="chooseLanguage('de-DE')"
            ><img class="mr-3" src="/assets/country-icons/de-DE.svg" />
            <p translate="navbar.german"></p>
          </a>
          <a class="navbar-item" (click)="chooseLanguage('en-US')">
            <img class="mr-3" src="/assets/country-icons/en-US.svg" />
            <p translate="navbar.english"></p
          ></a>
        </div>
      </div>
      <div class="navbar-item">
        <div class="buttons">
          <a
            class="button is-light"
            (click)="toggleUpright()"
            translate="navbar.upright"
          >
          </a>
          <a
            class="button is-light"
            (click)="logout()"
            translate="navbar.logout"
          >
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
