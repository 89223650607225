import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, map as lodashMap, sortBy } from 'lodash';
import { Observable, of, Subscription, map, Subject } from 'rxjs';
import { Api } from 'src/app/core/api/Api';
import { DOCUMENTTYPES } from './identify-document.constants';

@Injectable({
  providedIn: 'root',
})
export class IdentifyDocumentService {
  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) {}

  fetchNextJob(id: string) {
    return this.http.get(
      id ? `${Api.IdentifyDocument.Id}/${id}` : `${Api.IdentifyDocument.Next}`
    );
  }

  public deleteSubscription = Subscription.EMPTY;

  getNumJobs() {
    return this.http.get(Api.IdentifyDocument.Count);
  }

  submitJob(id: string, job: any) {
    if (job.newIban) {
      job.newIban = job.newIban.replace(/ /g, '');
    }
    if (job.oldIban) {
      job.oldIban = job.oldIban.replace(/ /g, '');
    }

    return this.http.put(`${Api.IdentifyDocument.Id}/${id}`, job);
  }

  deleteJob(id: string) {
    return this.http.delete(`${Api.IdentifyDocument.Id}/${id}`);
  }

  getSiblings(id: string) {
    return this.http.get(`${Api.IdentifyDocument.Id}/${id}/siblings`);
  }

  search(type: string, tenant: string, search: string = '') {
    // append trailing 'DE' if not present
    search = search.toUpperCase();
    search = search.replace(/ /g, '');
    if (!search.startsWith('DE')) {
      search = 'DE' + search;
    }
    return this.http
      .get(Api.IdentifyDocument.Search, {
        params: {
          type,
          tenant,
          limit: 10,
          q: search,
        },
      })
      .pipe(
        map((res: any) => {
          const sorted = sortBy(res, (r) => {
            return [r.closed];
          });
          return sorted;
        })
      );
  }

  queryBLZ(tenant: string) {
    return this.http.get('/api/v1/courier/pad/' + tenant);
  }

  get documentTypes() {
    const mappedItems = lodashMap(DOCUMENTTYPES, (item: any) => {
      return {
        label: this.translateService.instant(`documentType.${item.type}`),
        value: item.type,
        isOldBank: item.isOldBank,
      };
    });

    return [
      filter(mappedItems, { isOldBank: true }),
      filter(mappedItems, { isOldBank: false }),
    ];
  }
}
