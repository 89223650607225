import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

import { SharedModule } from './components/shared.module';
import { initializeKeycloak } from './core/auth/keycloak';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxModalView } from 'ngx-modalview';
import { createErrorHandler } from '@sentry/angular-ivy';

import { Version } from '@version';
import { FinoVersion } from '@fino-ui/common';
import { loadConfig } from './core/environment/config.loader';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function getlanguage(translate: TranslateService) {
  return translate.currentLang;
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null),
      );
      locationInitialized.then(() => {
        const langToSet = 'de-DE';
        translate.setDefaultLang('de-DE');
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`,
            );
          },
          () => {
            resolve(null);
          },
        );
      });
    });
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        NgxModalView.forRoot({
            container: 'modal-container',
        }),
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de-DE',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        KeycloakAngularModule,
        SharedModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            multi: true,
            deps: [HttpClient, KeycloakService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: FinoVersion,
            useValue: Version,
        },
        {
            provide: LOCALE_ID,
            useFactory: getlanguage,
            deps: [TranslateService],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
