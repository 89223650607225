import { Component, OnInit } from '@angular/core';
import { faLockOpen, faLock } from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';
import { UserRoles } from 'src/app/core/auth/roles';
import { NavbarMainRoutes, Routes } from 'src/app/core/routes/routes';
import { CaptureTransactionService } from 'src/app/modules/capture-transaction/capture-transaction.service';
import { CaptureWarrantService } from 'src/app/modules/capture-warrant/capture-warrant.service';
import { IdentifyDocumentService } from 'src/app/modules/identify-document/identify-document.service';
import { NavbarService } from './navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
})
export class NavbarComponent implements OnInit {
  constructor(
    private identifyDocumentService: IdentifyDocumentService,
    private captureTransactionService: CaptureTransactionService,
    private captureWarrantService: CaptureWarrantService,
    private navbarService: NavbarService,
    private authService: KeycloakService,
    private translateService: TranslateService,
    private storage: LocalStorageService,
  ) {}

  public loadCounts = this.navbarService.loadCounts;
  public isUpright = false;

  faLock = faLock;
  faLockOpen = faLockOpen;
  counts: any = {};
  Roles = UserRoles;
  userProfile: any = {};

  routes = NavbarMainRoutes;

  ngOnInit(): void {
    this.loadNumberOfJobs('identifyDocument');
    this.loadNumberOfJobs('captureTransaction');
    this.loadNumberOfJobs('captureTransactionQA');
    this.loadNumberOfJobs('captureWarrant');

    this.loadCounts.subscribe((type: string) => this.loadNumberOfJobs(type));
    this.authService
      .loadUserProfile()
      .then((userProfile) => (this.userProfile = userProfile));
  }

  hasRole(role: string | undefined) {
    if (!role) return false;
    const roles = this.authService.getUserRoles();
    return roles.includes(role);
  }

  hasOrganization(organizations: string[] | undefined) {
    if (!organizations || !this.userProfile) return false;
    return organizations.some((org) =>
      this.userProfile?.attributes?.organization?.includes(org),
    );
  }

  logout() {
    this.authService.logout();
  }

  toggleUpright() {
    this.navbarService.toggleUpright.next((this.isUpright = !this.isUpright));
  }

  get clarificationRoute() {
    return Routes.Clarification;
  }

  get hasMoreDropwdown() {
    return this.hasRole(UserRoles.CLARIFICATION);
  }

  get currentLang() {
    return this.translateService.currentLang;
  }

  chooseLanguage(lang: string) {
    this.translateService.use(lang);
    this.storage.store('language', lang);
    window.location.reload();
  }

  loadNumberOfJobs(type: string) {
    switch (type) {
      case 'identifyDocument':
        this.identifyDocumentService.getNumJobs().subscribe((res) => {
          this.counts['identifyDocument'] = res;
        });
        break;

      case 'captureTransaction':
        this.captureTransactionService.getNumJobs().subscribe((res: any) => {
          this.counts['captureTransaction'] = {
            locked: res.locked.TOTAL,
            open: res.open.TOTAL,
          };
        });
        break;

      case 'captureTransactionQA':
        this.captureTransactionService.getNumJobsQA().subscribe((res: any) => {
          this.counts['captureTransactionQA'] = {
            locked: res.locked.TOTAL,
            open: res.open.TOTAL,
          };
        });
        break;

      case 'captureWarrant':
        this.captureWarrantService.getNumJobs().subscribe((res: any) => {
          this.counts['captureWarrant'] = {
            locked: res.locked.TOTAL,
            open: res.open.TOTAL,
          };
        });
        break;
    }
  }
}
