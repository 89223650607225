/**
 * Generated `version.ts` file.
 *
 * THIS CODE IS AUTOMATICALLY GENERATED.
 *
 * Generated by @fino-ui/cli@2.2.11.
 * To regenerate, run `npx fino-ui version`.
 */
export const Version = {
  version: '1.0.0',
  revision: '3fc52d9',
  branch: 'HEAD',
  env: 'development',
};
