import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorService {
  constructor(private envService: EnvironmentService) {}

  initSentry() {
    Sentry.init({
      dsn: 'https://3d6d5f6ff46f8dbfcaf9fade1653817e@o46994.ingest.sentry.io/4506383205728256',
      release: this.envService.version,
      environment: this.envService.env,
    });
  }
}
