import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HighchartsChartModule } from 'highcharts-angular';

import { InputModule } from '@fino-ui/input';
import { FinoCommonModule } from '@fino-ui/common';
import { FinoFormsModule } from '@fino-ui/forms';
import { SelectModule } from '@fino-ui/select';
import { FinancesCommonModule } from '@fino-ui/finances';
import { ButtonModule } from '@fino-ui/button';

import { IbanTypeaheadComponent } from './iban-typeahead/iban-typeahead.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DirectDebitsComponent } from './direct-debits/direct-debits.component';
import { ReceivedPaymentsComponent } from './recieved-payments/recieved-payments.component';
import { StandingOrdersComponent } from './standing-orders/standing-orders.component';
import { TenantInfosComponent } from './tenant-infos/tenant-infos.component';
import { IbanColorPipe } from '../core/pipes/iban-color.pipe';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { CaptureTransactionIbanCheckComponent } from './capture-transaction-iban-check/capture-transaction-iban-check.component';
import { CreditorPipe } from '../core/pipes/creditor.pipe';
import { SortIconComponent } from './sort-icon/sort-icon.component';
import { FreshdeskComponent } from './freshdesk/freshdesk.component';
import { ApproveModalComponent } from './approve-modal/approve-modal.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    FontAwesomeModule,
    CommonModule,
    RouterModule,
    PdfJsViewerModule,
    DragDropModule,
    NgxWebstorageModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    ClipboardModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    ToastrModule.forRoot(),

    //@fino-ui
    ButtonModule,
    FinoCommonModule,
    FormsModule,
    FinoFormsModule,
    SelectModule,
    InputModule,
    FinancesCommonModule,
  ],
  exports: [
    FontAwesomeModule,
    PdfJsViewerModule,
    NavbarComponent,
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    ClipboardModule,
    FormsModule,
    HighchartsChartModule,
    RouterModule,
    TranslateModule,
    IbanTypeaheadComponent,
    DirectDebitsComponent,
    ReceivedPaymentsComponent,
    StandingOrdersComponent,
    TenantInfosComponent,
    FreshdeskComponent,
    NgxWebstorageModule,
    NgxMaskDirective,
    NgxMaskPipe,
    IbanColorPipe,
    CreditorPipe,
    CaptureTransactionIbanCheckComponent,
    SortIconComponent,
    ApproveModalComponent,

    //@fino-ui
    ButtonModule,
    FinoCommonModule,
    FormsModule,
    FinoFormsModule,
    SelectModule,
    InputModule,
    FinancesCommonModule,
  ],
  declarations: [
    NavbarComponent,
    IbanTypeaheadComponent,
    DirectDebitsComponent,
    ReceivedPaymentsComponent,
    StandingOrdersComponent,
    TenantInfosComponent,
    IbanColorPipe,
    CreditorPipe,
    TypeaheadComponent,
    CaptureTransactionIbanCheckComponent,
    SortIconComponent,
    FreshdeskComponent,
    ApproveModalComponent,
  ],
  providers: [provideNgxMask(maskConfig)],
})
export class SharedModule {}
