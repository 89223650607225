import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationRouteGuard } from './core/auth/guards/organizationRouteGuard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [],
      },
      {
        path: 'jobs/identifyDocument',
        loadChildren: () =>
          import('./modules/identify-document/identify-document.module').then(
            (m) => m.IdentifyDocumentModule,
          ),
        canActivate: [],
      },
      {
        path: 'jobs/mergeSiblings',
        loadChildren: () =>
          import('./modules/siblings/siblings.module').then(
            (m) => m.SiblingsModule,
          ),
        canActivate: [],
      },
      {
        path: 'jobs/captureTransaction',
        loadChildren: () =>
          import(
            './modules/capture-transaction/capture-transaction.module'
          ).then((m) => m.CaptureTransactionModule),
        canActivate: [],
        data: {
          isQualityAssurance: false,
        },
      },
      {
        path: 'clarification',
        loadChildren: () =>
          import('./modules/clarification/clarification.module').then(
            (m) => m.ClarificationModule,
          ),
        canActivate: [],
      },
      {
        path: 'ticketOverview',
        loadChildren: () =>
          import('./modules/ticket-overview/ticket-overview.module').then(
            (m) => m.TicketOverviewModule,
          ),
        canActivate: [OrganizationRouteGuard],
      },
      {
        path: 'jobs/captureTransactionQA',
        loadChildren: () =>
          import(
            './modules/capture-transaction/capture-transaction.module'
          ).then((m) => m.CaptureTransactionModule),
        canActivate: [],
        data: {
          isQualityAssurance: true,
        },
      },
      {
        path: 'jobs/captureWarrant',
        loadChildren: () =>
          import('./modules/capture-warrant/capture-warrant.module').then(
            (m) => m.CaptureWarrantModule,
          ),
        canActivate: [],
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [OrganizationRouteGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
