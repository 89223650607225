import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

//@ts-ignore
import Darkmode from 'darkmode-js';
import { LocalStorageService } from 'ngx-webstorage';
import { SentryErrorService } from './core/error/sentry-error.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private storage: LocalStorageService,
    private translateService: TranslateService,
    private sentryErrorService: SentryErrorService,
  ) {
    const darkmode = new Darkmode({
      bottom: '15px',
      right: 'unset',
      left: '160px',
      time: '0.5s',
      label: '🌓',
    });
    darkmode.showWidget();
  }
  title = 'x2-courier-ui';

  ngOnInit(): void {
    const lang = this.storage.retrieve('language');
    if (lang) {
      this.translateService.use(lang);
    }

    this.sentryErrorService.initSentry();
  }
}
