import {
  faLandmark,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';

export const REJECTION_REASONS = {
  OK: 'OK',
  NEEDS_CLARIFICATION: 'NEEDS_CLARIFICATION',
  NEW_IBAN_MISSING: 'NEW_IBAN_MISSING',
  INCOMPLETE_WITHOUT_IBAN: 'INCOMPLETE_WITHOUT_IBAN',
  UNREADABLE_WITH_IBAN: 'UNREADABLE_WITH_IBAN',
  INCOMPLETE_WITH_IBAN: 'INCOMPLETE_WITH_IBAN',
  OTHER: 'OTHER',
};

export const REJECTION_REASONS_GENERAL = [
  {
    value: REJECTION_REASONS.OK,
    higlightedBy: [REJECTION_REASONS.OK],
    translation: 'OK',
    icon: faThumbsUp,
  },
  {
    value: REJECTION_REASONS.NEEDS_CLARIFICATION,
    higlightedBy: [REJECTION_REASONS.NEEDS_CLARIFICATION],
    translation: 'NEEDS_CLARIFICATION',
    icon: faLandmark,
  },
  {
    value: REJECTION_REASONS.OTHER,
    higlightedBy: [
      REJECTION_REASONS.NEW_IBAN_MISSING,
      REJECTION_REASONS.INCOMPLETE_WITHOUT_IBAN,
      REJECTION_REASONS.UNREADABLE_WITH_IBAN,
      REJECTION_REASONS.INCOMPLETE_WITH_IBAN,
      REJECTION_REASONS.OTHER,
      'ELSE',
    ],
    translation: 'ELSE',
    icon: faThumbsDown,
  },
];

export const REJECTION_REASONS_DETAILED = [
  REJECTION_REASONS.NEW_IBAN_MISSING,
  REJECTION_REASONS.INCOMPLETE_WITHOUT_IBAN,
  REJECTION_REASONS.UNREADABLE_WITH_IBAN,
  REJECTION_REASONS.INCOMPLETE_WITH_IBAN,
  REJECTION_REASONS.OTHER,
];

export const DOCUMENTTYPES = [
  {
    type: 'WARRANT',
    isOldBank: true,
  },
  {
    type: 'BALANCE_LIST',
    isOldBank: false,
  },
  {
    type: 'REJECTED_BALANCE_LIST',
    isOldBank: true,
  },
  {
    type: 'REJECTED_WARRANT',
    isOldBank: false,
  },
  {
    type: 'ADMONITION_BALANCE_LIST',
    isOldBank: true,
  },
  {
    type: 'MISC',
    isOldBank: false,
  },
];
