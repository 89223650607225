import { Organizations } from '../auth/organizations';
import { UserRoles } from '../auth/roles';

const pathPrefix = '';

export const Routes = {
  CaptureWarrant: `${pathPrefix}/jobs/captureWarrant`,
  CaptureTransaction: `${pathPrefix}/jobs/captureTransaction`,
  CaptureTransactionQA: `${pathPrefix}/jobs/captureTransactionQA`,
  IdentifyDocument: `${pathPrefix}/jobs/identifyDocument`,
  MergeSiblings: `${pathPrefix}/jobs/mergeSiblings`,
  Clarification: `${pathPrefix}/clarification`,
  TicketOverview: `${pathPrefix}/ticketOverview`,
  Home: `${pathPrefix}/home`,
};

export const NavbarMainRoutes = [
  {
    url: Routes.IdentifyDocument,
    name: 'identifyDocument',
    role: UserRoles.DOCUMENT_CLASSIFICATION,
  },
  {
    url: Routes.CaptureTransaction,
    name: 'captureTransaction',
    role: UserRoles.TRANSACTION_DATA_CAPTURE,
  },
  {
    url: Routes.CaptureTransactionQA,
    name: 'captureTransactionQA',
    role: UserRoles.TRANSACTION_DATA_CAPTURE_QA,
  },
  {
    url: Routes.CaptureWarrant,
    name: 'captureWarrant',
    role: UserRoles.WARRANT_CAPTURE,
  },
  {
    url: Routes.TicketOverview,
    name: 'ticketOverview',
    organizations: [Organizations.SMM, Organizations.ATRUVIA],
    showNoLocks: true,
  },
];
