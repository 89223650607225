import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { assign, filter, forEach, forOwn, isString, map, set } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { Api } from 'src/app/core/api/Api';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class CaptureTransactionService {
  constructor(private http: HttpClient) {}

  submitSubscription = Subscription.EMPTY;
  public submitJobObservable = new Subject();

  fetchNextJob(id: string, isQa = false) {
    if (isQa) {
      return this.http.get(
        id ? `${Api.BalanceListQa.Id}/${id}` : `${Api.BalanceListQa.Next}`,
      );
    }
    return this.http.get(
      id ? `${Api.BalanceList.Id}/${id}` : `${Api.BalanceList.Next}`,
    );
  }

  unlockBalanceList(id: string, isQA = false) {
    return this.http.delete(
      `${isQA ? Api.BalanceListQa.Id : Api.BalanceList.Id}/${id}/lock`,
    );
  }

  getCreditors(search: string, type: string) {
    const params = { limit: 10 };
    set(params, type, search);
    return this.http.get(Api.Creditors, {
      params,
    });
  }

  getNumJobs() {
    return this.http.get(Api.BalanceList.Count);
  }

  getNumJobsQA() {
    return this.http.get(Api.BalanceListQa.Count);
  }

  submitJob(id: string, job: any, isQualityAssurance = false) {
    if (isQualityAssurance) {
      return this.http.put(Api.BalanceListQa.Id, job);
    }
    return this.http.put(Api.BalanceList.Id, job);
  }

  reportDocument(id: string, comment: string, finances: any) {
    return this.http.post(`${Api.BalanceList.Id}/${id}/review`, {
      comment,
      finances,
    });
  }

  selectAllFinances(finances: any[]): any[] {
    return map(finances, (f: any) => {
      f.selected = true;
      f.id = uuidv4();
      return f;
    });
  }

  prepareDataForSubmission(
    directDebits: any,
    receivedPayments: any,
    standingOrders: any,
    job: any,
    isQualityAssurance: boolean,
    approvedWithCorrections: boolean,
    extractionAccepted: boolean,
    forceQA: boolean,
  ) {
    let data = {
      finances: {
        directDebits: filter(directDebits, { selected: true }),
        receivedPayments: filter(receivedPayments, { selected: true }),
        standingOrders: filter(standingOrders, { selected: true }),
      },
      pendingBalanceListId: job.id,
      previousJobId: job.previousJobId,
    };
    if (isQualityAssurance) {
      data = assign(data, {
        _id: job._id,
        pendingBalanceListId: job.pendingBalanceListId,
        approvedFinances: data.finances,
        approvedWithCorrections: approvedWithCorrections,
        extractionAccepted: extractionAccepted,
      });
    }
    if (!isQualityAssurance) {
      data = assign(data, { forceQA });
    }

    return data;
  }

  prepareFromFinctract(finances: any) {
    forOwn(finances, (value, key) => {
      forEach(value, (e) => {
        // select all finances
        e.selected = true;
        e.id = uuidv4();

        // executionDay must be int, handles OCR stuff
        if (e.executionDay && isString(e.executionDay)) {
          e.executionDay = parseInt(e.executionDay);
        }
        return e;
      });
    });

    return finances;
  }

  applyCapturedFinances(job: any) {
    const { finances } = job;
    const appliedFinances = {
      directDebits: [] as any,
      receivedPayments: [] as any,
      standingOrders: [] as any,
    };

    if (finances.directDebits) {
      appliedFinances.directDebits = this.selectAllFinances(
        finances.directDebits,
      );
    }
    if (finances.receivedPayments) {
      appliedFinances.receivedPayments = this.selectAllFinances(
        finances.receivedPayments,
      );
    }
    if (finances.standingOrders) {
      appliedFinances.standingOrders = this.selectAllFinances(
        finances.standingOrders,
      );
    }
    return appliedFinances;
  }
}
