import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Api } from 'src/app/core/api/Api';

@Injectable({
  providedIn: 'root',
})
export class CaptureWarrantService implements OnInit {
  constructor(private http: HttpClient) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  getNumJobs() {
    return this.http.get(Api.CaptureWarrant.Count);
  }

  submitJob(id: string, job: any) {
    return this.http.put(`${Api.CaptureWarrant.Id}/${id}`, job);
  }

  fetchNextJob(id: string) {
    return this.http.get(
      id ? `${Api.CaptureWarrant.Id}/${id}` : `${Api.CaptureWarrant.Next}`
    );
  }
}
