const prefix = '/api/v1';

export const Api = {
  IdentifyDocument: {
    Id: `${prefix}/courier/jobs`,
    Next: `${prefix}/courier/jobs/next`,
    Search: `${prefix}/courier/search`,
    Count: `${prefix}/courier/jobs/count`,
  },
  BalanceListQa: {
    Count: `${prefix}/courier/qa/transactionList/count`,
    Id: `${prefix}/courier/qa/transactionList`,
    Next: `${prefix}/courier/qa/transactionList/next`,
  },
  BalanceList: {
    Id: `${prefix}/courier/balanceList`,
    Next: `${prefix}/courier/balanceList/next`,
    Count: `${prefix}/courier/balanceList/count`,
  },
  CaptureWarrant: {
    Count: `${prefix}/courier/warrant/count`,
    Next: `${prefix}/courier/warrant/next`,
    Id: `${prefix}/courier/warrant`,
  },
  Clarification: `${prefix}/courier/clarification/balancelistsInReview`,
  Siblings: {
    mergePreview: `${prefix}/courier/jobs/merge-preview`,
    merge: `${prefix}/courier/jobs/merge`,
  },
  Creditors: `${prefix}/courier/creditors`,
  TicketOverview: {
    Overview: (organization) => `${prefix}/courier/${organization}/overview`,
    Tenants: (organization) => `${prefix}/courier/${organization}/tenants`,
    Ticket: (organization) => `${prefix}/${organization}/ticket`,
    Tenant: (organization) => `${prefix}/courier/${organization}/link`,
  },
  Config: `${prefix}/courier/config`,
};
