import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  public loadCounts = new Subject<string>();
  public toggleUpright = new Subject<boolean>();

  constructor() {}
}
